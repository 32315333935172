import { VegaButton, VegaCard, VegaFlex, VegaFont, VegaGrid, VegaIcon } from '@heartlandone/vega-react';
import router from 'next/router';
import { useTranslation } from 'react-i18next';

import { ShipmentDetails } from 'types/equipmentTracker';
import { routes } from 'types/pages';

interface EquipmentHeaderProps {
  shipmentDetails: ShipmentDetails[];
}

const EquipmentHeader = ({ shipmentDetails }: EquipmentHeaderProps) => {
  const { t } = useTranslation('equipment-tracker');

  const handleButtonPress = () => {
    router.push(routes.myEquipment);
  };

  return (
    <VegaCard backgroundColor="bg-quaternary" padding="size-16" variant="border">
      <VegaFlex justifyContent="space-between" direction="row" gap="size-16">
        <VegaFlex alignItems="center" gap="size-16">
          <VegaIcon icon="fa-solid fa-truck" color="text-link" size="size-48" />
          <VegaGrid row={2} gap="size-4">
            <VegaFont variant="font-h5">{t('title')}</VegaFont>
            <VegaFlex flexWrap={'wrap'}>
              {shipmentDetails.map((equipmentItem, index) => {
                const isLast = index === shipmentDetails.length - 1;
                return (
                  <VegaFont
                    variant="font-p2-short"
                    color="text-secondary"
                    key={equipmentItem.equipmentName + equipmentItem.tid}
                  >
                    {equipmentItem.equipmentName}
                    {!isLast && ', '}
                  </VegaFont>
                );
              })}
            </VegaFlex>
          </VegaGrid>
        </VegaFlex>

        <VegaFlex justifyContent="space-between" alignItems="center">
          <VegaButton variant="secondary" icon={'fa fa-arrow-right'} iconAlign="right" onClick={handleButtonPress}>
            {t('equipmentTrackerButton')}
          </VegaButton>
        </VegaFlex>
      </VegaFlex>
    </VegaCard>
  );
};

export default EquipmentHeader;
