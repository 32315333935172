import { useQuery } from '@tanstack/react-query';

import myEquipmentService, { MyEquipmentUrls } from 'api/services/myEquipment/myEquipmentService';
import { ShipmentDetails } from 'types/equipmentTracker';

const useTrackingService = () => {
  const { data: shipmentDetails = [], refetch: fetchShipmentDetails } = useQuery<ShipmentDetails[]>({
    queryKey: [MyEquipmentUrls.getUndeliveredEquipmentShipments],
    queryFn: myEquipmentService.GetUndeliveredEquipmentShipments,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: 0,
  });

  return {
    shipmentDetails,
    fetchShipmentDetails,
  };
};

export default useTrackingService;
