import React from 'react';

import { VegaCard, VegaFlex, VegaFont } from '@heartlandone/vega-react';
import { useTranslation } from 'next-i18next';

import useTrackerServiceData from 'hooks/equipmentTracker';

import EquipmentHeader from './equipmentHeader';
import EquipmentResourceList from './equipmentResourceList';

const EquipmentTracker = () => {
  const { t } = useTranslation('equipment-tracker');
  const { shipmentDetails } = useTrackerServiceData();

  return (
    <VegaCard backgroundColor="bg-primary" padding={{ top: 'size-32', left: 'size-32', right: 'size-32' }}>
      <VegaFlex direction="col">
        <EquipmentHeader shipmentDetails={shipmentDetails} />
        <VegaFlex direction="col" margin={{ top: 'size-32', bottom: 'size-32' }}>
          <VegaFont variant="font-h6">{t('resources')}</VegaFont>
          <VegaFont variant="font-p2-short" color="text-secondary">
            {t('references')}
          </VegaFont>
          <VegaFlex margin={{ top: 'size-16', bottom: 'size-16' }}>
            <EquipmentResourceList shipmentDetails={shipmentDetails} />
          </VegaFlex>
        </VegaFlex>
      </VegaFlex>
    </VegaCard>
  );
};

export default EquipmentTracker;
