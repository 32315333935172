import React, { use, useMemo } from 'react';

import { VegaButtonLink, VegaCard, VegaFont, VegaGrid } from '@heartlandone/vega-react';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { CanAccessBatchesAndTransactions } from 'components/user/auth/permissions';
import { UserContext } from 'components/user/auth/userContext';
import UserNameIcon from 'components/user/userNameIcon';
import useOpenBatchAmountData from 'hooks/reports/useOpenBatchAmountData';
import { AppContext } from 'types/app';
import { routes } from 'types/pages';
import { IdentifierTypes } from 'types/user';
import { getDisplayName, numberWithCommas } from 'utility/helper/helperFunctions';
import { DeviceIsMobile } from 'utility/showOnDevice';

import styles from './index.module.css';

const WelcomeCard: React.FC = () => {
  const user = use(UserContext);
  const isMobile = DeviceIsMobile();
  const appContext = use(AppContext);
  const router = useRouter();
  const unverifiedText: string =
    user.activeCustomer?.idType === IdentifierTypes.MERCHANT_SEQUENCE_KEY ? 'subtitle-msk' : 'subtitle-unverified';
  const { t } = useTranslation('welcome-card');
  const { data, dataUpdatedAt } = useOpenBatchAmountData(CanAccessBatchesAndTransactions(user));

  const displayName = useMemo(() => getDisplayName(user), [user]);

  return (
    <VegaCard padding={'size-24'} className={styles.welcomeCard}>
      <VegaGrid column={2} style={{ gridTemplateColumns: `${isMobile ? '60' : '76'}px 1fr` }}>
        <div style={{ gridRow: 'span 2' }}>
          <UserNameIcon theme={appContext.theme} size="WelcomeCard" />
        </div>
        <VegaFont color="text-primary" variant={'font-h5'} className={styles.welcomeMessage}>
          {t('welcome', { displayName: displayName })}
        </VegaFont>
        <VegaFont
          variant="font-field-label"
          color="text-secondary"
          style={{ gridColumn: isMobile ? 'span 2' : 'span 1', marginTop: isMobile ? '16px' : '0' }}
        >
          {!user.isVerified && <>{t(unverifiedText)}</>}
          {user.isVerified && (
            <>
              <span>{t('subtitle1')}&nbsp;</span>
              {user.userHasPaymentsAccounts && CanAccessBatchesAndTransactions(user) && (
                <>
                  <span>
                    {t('subtitle-payments', {
                      time: format(dataUpdatedAt, 'h:mmaa'),
                      date: format(dataUpdatedAt, 'MMM d, yyyy'),
                    })}
                  </span>
                  <VegaButtonLink
                    data-testid="button-link-batches-and-transactions"
                    onVegaClick={() => {
                      router.push(routes.batchesAndTransactions);
                    }}
                    label={`$${numberWithCommas(data?.totalAmount ?? 0)}`}
                  />
                </>
              )}
            </>
          )}
        </VegaFont>
      </VegaGrid>
    </VegaCard>
  );
};

export default WelcomeCard;
